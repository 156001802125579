<!--  -->
<template>
  <div class="customise">
    <template v-if="moderText.length > 0">
      <van-field
        v-for="item in moderText"
        :key="item.id"
        class="model_type1 color"
        v-model="item.values"
        :label="item.definitionName"
        placeholder="请输入"
      />
    </template>
    <template v-if="moderNumber.length > 0"
      >
      <van-field
        v-model="item.values"
        class="model_type2 color"
        v-for="item in moderNumber"
        :key="item.id"
        :label="item.definitionName"
        placeholder="请输入"
    />
    </template>
    <template v-if="moderTime.length > 0"
      ><van-field
        v-for="item in moderTime"
        :key="item.id"
        v-model="item.values"
        readonly
        clickable
        class="model_type3 color"
        :label="item.definitionName"
        placeholder="点击选择时间"
        @click="dataShow = true"
      />
      <van-popup v-model="dataShow" position="bottom">
        <van-datetime-picker
          type="time"
          @confirm="headerItemDateChange"
          @cancel="dataShow = false"
        />
      </van-popup>
    </template>
    <template v-if="moderRa.length > 0">
      <van-field
        v-for="item in moderRa"
        :key="item.id"
        class="model_type5 color"
        :label="item.definitionName"
      >
        <template #input>
          <van-radio-group v-model="item.values" direction="horizontal">
            <van-radio
              :name="raItem"
              v-for="raItem in item.definitionValueList"
              :key="raItem"
              >{{ raItem }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
    </template>
    <template v-if="moderCh.length > 0">
      <van-field
        readonly
        v-for="item in moderCh"
        :key="item.id"
        class="model_type6 color"
        :label="item.definitionName"
      >
        <template #input>
          <van-checkbox-group
            v-model="item.valueList"
            direction="horizontal"
            @click.stop
          >
            <van-checkbox
              :name="chItem"
              v-for="chItem in item.definitionValueList"
              :key="chItem"
              @click.stop
              >{{ chItem }}</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field></template
    >
    <template v-if="moderImg.length > 0"
      ><van-field
        v-for="(item, index) in moderImg"
        :key="item.id"
        readonly
        clickable
        class="model_type4 color"
        :label="item.definitionName"
        ><template #input>
          <div>
            <div class="up_img" v-if="item.values">
              <img :src="item.values" alt="" srcset="" style="width: 100%; height: 100%;"/>
              <span class="icon icon-shanchu4" @click="deleteImg"></span>
            </div>
            <van-uploader v-else :after-read="afterRead" :max-count="1" @click-upload="saveIndex(index)">
            </van-uploader>
          </div>
        </template>
      </van-field>
    </template>
  </div>
</template>

<script>
import { getDefinitionModel, upload, onModulSubmit } from "@/api/work.js";
import { mapGetters } from "vuex";
export default {
  name: "customise",
  components: {},
  props:{
    defType:{
      type:Number,
      required:true
    }
  },
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    //这里存放数据
    return {
      imagIndex: null, //图片的下标
      moderText: [], //definitionType == 1
      moderNumber: [], //definitionType == 2
      show: false,
      dataShow: false,
      colorListShow: false, //颜色选择
      moderTime: [], //definitionType == 3
      moderImg: [], //definitionType == 4
      moderRa: [], //definitionType == 5
      moderCh: [], //definitionType == 6
    };
  },
  //监听属性 类似于data概念
  watch: {},
  //方法集合
  methods: {
    headerItemDateChange(value) {
      if (this.moderTime.length > 1) {
        const index = this.moderTime.findIndex((v) => v.id == this.dataId);
        this.moderTime[index].values = value;
      } else {
        this.moderTime[0].values = value;
      }
      this.dataId = "";
      this.dataShow = !this.dataShow;
    },
    deleteImg(file) {
      this.moderImg[0].values = "";
    },
    saveIndex(index) {
      this.imagIndex = index
    },
    urlSplit(data) {
      if (data) {
        const arr = data.split('/')
        const str = process.env.VUE_APP_BASE_API
        console.log(str, '配置文件')
        const sliceStr =  str.replace(/api/gi, '')
        return sliceStr + arr[3] + '/' + arr[4]
      } else {
        return data
      }

    },
    async afterRead(file) {
      try {
        const params = new FormData();
        params.append("type", "productDefinition");
        params.append("file", file.file);
        const res = await upload(params);
        this.$toast("上传成功");
        this.moderImg[this.imagIndex].values = this.urlSplit(res);
      } catch (err) {
        console.log(err);
        this.$toast("上传失败");
      }
    },
    async getDefinitionModel() {
      var params = {
        jsonData: "",
        departmentCode: this["departmentCode"],
        type: this.defType, //报工四
      };
      const res = await getDefinitionModel(params);
      const modelType = res;
      //处理模型数据
      modelType.filter((v) => {
        // 定义类型1、文本2、数字3、时间4、图片5、单选框6、复选框
        if (v.definitionType == 1) {
          this.moderText.push({
            id: v.id,
            definitionName: v.definitionName,
            values: v.values,
          });
        } else if (v.definitionType == 2) {
          this.moderNumber.push({
            id: v.id,
            definitionName: v.definitionName,
            values: v.values,
            propertiesValue: v.propertiesValue,
          });
        } else if (v.definitionType == 3) {
          this.moderTime.push({
            id: v.id,
            definitionName: v.definitionName,
            values: v.values,
          });
        } else if (v.definitionType == 4) {
          this.moderImg.push({
            id: v.id,
            definitionName: v.definitionName,
            values: v.values,
          });
        } else if (v.definitionType == 5) {
          this.moderRa.push({
            id: v.id,
            definitionName: v.definitionName,
            values: v.values,
            definitionValueList: v.definitionValueList,
          });
        } else if (v.definitionType == 6) {
          this.moderCh.push({
            id: v.id,
            definitionName: v.definitionName,
            valueList: v.valueList == null ? [] : v.valueList,
            definitionValueList: v.definitionValueList,
          });
        }
      });
    },
    async onModulSubmit() {
      const _that = this;
      var params = [];
      if (_that.moderText.length > 0) {
        _that.moderText.forEach((v) => {
          params.push(v);
        });
      }
      if (_that.moderNumber.length > 0) {
        _that.moderNumber.forEach((v) => {
          params.push(v);
        });
      }
      if (_that.moderTime.length > 0) {
        _that.moderTime.forEach((v) => {
          params.push(v);
        });
      }
      if (_that.moderImg.length > 0) {
        _that.moderImg.forEach((v) => {
          params.push(v);
        });
      }
      if (_that.moderRa.length > 0) {
        _that.moderRa.forEach((v) => {
          params.push(v);
        });
      }
      if (_that.moderCh.length > 0) {
        _that.moderCh.forEach((v) => {
          v.values = v.valueList.toString();
          params.push(v);
        });
      }
      params.departmentCode = this["departmentCode"];
      params.type = this.defType;
      const res = await onModulSubmit(params);
      return res
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDefinitionModel();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.color {
  width: 95%;
  box-sizing: border-box;
  margin: auto;
  border-radius: 10px;
  margin-top: 30px !important;
}
.submit {
  margin-top: 40px !important;
}
.model_type1 {
  border-left: 5px solid #1989fa;
}
.model_type2 {
  border-left: 5px solid #07c160;
}
.model_type3 {
  border-left: 5px solid rgb(114, 50, 221);
}
.model_type4 {
  align-items: center;
  border-left: 5px solid red;
  .up_img {
    width: 20vw;
    height: 20vw;
    position: relative;
    img {
      width: 100%;
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      color: red;
    }
  }
}
.model_type5 {
  border-left: 5px solid #312626;
  align-items: center;
  .van-radio--horizontal {
    margin-top: 5px;
  }
}
.model_type6 {
  border-left: 5px solid #4e89b3;
  align-items: center;
  .van-checkbox {
    margin-top: 5px;
  }
}
</style>
